import { Link } from 'gatsby';
import React from 'react';

// This file is used at : [ service-SaasMVP.js ]

const BookCallServiceComponent = (props) => {
  const {
    bookCallServiceTitle,
    bookCallServiceDescription,
    isFirstService,
    isSecondService,
  } = props;

  return (
    <div className='full-container'>
      <div className='bookCall-service-component-wrapper'>
        <p
          className={`${
            isFirstService && 'bookCall-service-component-wrapper-title1'
          } ${isSecondService && 'bookCall-service-component-wrapper-title2'}`}
        >
          {bookCallServiceTitle}
        </p>
        <p
          className={`${
            isFirstService && 'bookCall-service-component-wrapper-description1'
          } ${
            isSecondService && 'bookCall-service-component-wrapper-description2'
          }`}
        >
          {bookCallServiceDescription}
        </p>
        <Link
          to='https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1DvKx-W5bQIQqYRPigG4-BJhmnc3tqIcrtPt8q4Tg_UCGIc7Y65IUVAloWN8BlJkbqrmDwb4r2'
          target='_blank'
          className={`${
            isFirstService && 'bookCall-service-component-wrapper-link1'
          } ${isSecondService && 'bookCall-service-component-wrapper-link2'}`}
        >
          Book A Call
        </Link>
      </div>
    </div>
  );
};

export default BookCallServiceComponent;
