import { Link } from 'gatsby';
import React, { useState } from 'react';

const ItExpandTeamComponent = props => {
  const {
    showText = false,
    title,
    description1,
    description2,
    description3,
    description4,
    description5,
    description6,
  } = props;
  const [showAllText, setShowAllText] = useState(showText ? showText : false);

  const toggleText = () => setShowAllText(!showAllText);
  return (
    <div className='it-expand'>
      <div className='full-container'>
        <div className='it-expand__wrapper'>
          <p
            className='it-expand__title'
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div
            className={`it-expand__content  ${showAllText && 'show-all-text'}`}
          >
            <p
              className='it-expand__content-text'
              dangerouslySetInnerHTML={{ __html: description1 }}
            />
            <p
              className='it-expand__content-text'
              dangerouslySetInnerHTML={{ __html: description2 }}
            />
            <p
              className={`it-expand__content-text ${
                !showAllText && 'text-opacity'
              }`}
              dangerouslySetInnerHTML={{ __html: description3 }}
            />
            <p
              className={`it-expand__content-text ${
                !showAllText && 'text-opacity'
              }`}
              dangerouslySetInnerHTML={{ __html: description4 }}
            />
            <p
              className={`it-expand__content-text ${
                !showAllText && 'text-opacity'
              }`}
              dangerouslySetInnerHTML={{ __html: description5 }}
            />
            <p
              className={`it-expand__content-text ${
                !showAllText && 'text-opacity'
              }`}
              dangerouslySetInnerHTML={{ __html: description6 }}
            />
          </div>
          {showText ? (
            <Link
              to='/contact'
              className='it-expand__button'
              style={{ textDecoration: 'none' }}
            >
              Contact Us
            </Link>
          ) : (
            <button onClick={toggleText} className='it-expand__button'>
              {showAllText ? 'Show less' : 'Read More'}
            </button>
          )}
        </div>
      </div>
      <div
        className={`cards-section__blur-overlay ${
          showAllText ? '' : 'blur-overlay'
        }`}
      ></div>
    </div>
  );
};

export default ItExpandTeamComponent;
