import React from 'react';
import Icon from '../images/plus-icon.svg';
import { Accordion } from 'react-bootstrap';
const AccordionComponent = ({ title, subtitle, arr1, arr2 }) => {
  return (
    <div className='accordion-section'>
      <p
        className='accordion-section__title'
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <p
        className='accordion-section__desc'
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
      <div className='full-container'>
        <div style={{ padding: '0 20px' }}>
          <Accordion
            defaultActiveKey={'0'}
            className='accordion-section__wrapper'
          >
            <div className='accordion-section__wrapper_1'>
              {arr1.map((item, i) => (
                <Accordion.Item
                  key={i}
                  eventKey={item.eventKey}
                  style={{ width: '100%', border: 'none' }}
                >
                  <div>
                    <Accordion.Header className='accordion-section__card'>
                      <p
                        className='accordion-section__card-title'
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                      <img
                        src={Icon}
                        className='accordion-section__card-icon'
                      />
                    </Accordion.Header>
                    <Accordion.Body>{item.description}</Accordion.Body>
                  </div>
                </Accordion.Item>
              ))}
            </div>

            <div className='accordion-section__wrapper_2'>
              {arr2.map((item, i) => (
                <Accordion.Item
                  key={i}
                  eventKey={item.eventKey}
                  style={{ width: '100%', border: 'none' }}
                >
                  <div style={{ width: '100%' }}>
                    <Accordion.Header className='accordion-section__card'>
                      <p
                        className='accordion-section__card-title'
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                      <img
                        src={Icon}
                        className='accordion-section__card-icon'
                      />
                    </Accordion.Header>
                    <Accordion.Body>{item.description}</Accordion.Body>
                  </div>
                </Accordion.Item>
              ))}
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default AccordionComponent;
