import React from 'react';

const TimelineV1Component = props => {
  const {
    timelineTitle,
    timelineSubtitle,
    timelineDescription,
    timelineTextTitle1,
    timelineTextDescription1,
    timelineTextTitle2,
    timelineTextDescription2,
    timelineTextTitle3,
    timelineTextDescription3,
    timelineTextTitle4,
    timelineTextDescription4,
    timelineTextTitle5,
    timelineTextDescription5,
    timelineTextTitle6,
    timelineTextDescription6,
  } = props;
  return (
    <div className='timeline-v1'>
      <div className='timeline-v1-container'>
        <div className='full-container'>
          <div className='timeline-v1-container-text'>
            <p
              className='timeline-v1-container-text-title'
              dangerouslySetInnerHTML={{ __html: timelineTitle }}
            />
            <p
              className='timeline-v1-container-text-subtitle'
              dangerouslySetInnerHTML={{ __html: timelineSubtitle }}
            />
            <p
              className='timeline-v1-container-text-description'
              dangerouslySetInnerHTML={{ __html: timelineDescription }}
            />
          </div>
          <div class='container py-5'>
            <div class='main-timeline'>
              <div class='timeline right'>
                <div class='card'>
                  <div class='card-body  p-4'>
                    <h3
                      className='timeline-container-content-title'
                      dangerouslySetInnerHTML={{ __html: timelineTextTitle1 }}
                    />
                    <p
                      className='timeline-container-content-description'
                      dangerouslySetInnerHTML={{
                        __html: timelineTextDescription1,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class='timeline left'>
                <div class='card align-items-md-end'>
                  <div class='card-body align-items-md-end p-4'>
                    <h3
                      className='timeline-container-content-title'
                      dangerouslySetInnerHTML={{ __html: timelineTextTitle2 }}
                    />
                    <p
                      className='timeline-container-content-description'
                      dangerouslySetInnerHTML={{
                        __html: timelineTextDescription2,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class='timeline right'>
                <div class='card'>
                  <div class='card-body  p-4'>
                    <h3
                      className='timeline-container-content-title'
                      dangerouslySetInnerHTML={{ __html: timelineTextTitle3 }}
                    />
                    <p
                      className='timeline-container-content-description'
                      dangerouslySetInnerHTML={{
                        __html: timelineTextDescription3,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class='timeline left'>
                <div class='card align-items-md-end'>
                  <div class='card-body align-items-md-end p-4'>
                    <h3
                      className='timeline-container-content-title'
                      dangerouslySetInnerHTML={{ __html: timelineTextTitle4 }}
                    />
                    <p
                      className='timeline-container-content-description'
                      dangerouslySetInnerHTML={{
                        __html: timelineTextDescription4,
                      }}
                    />
                  </div>
                </div>
              </div>
              {timelineTextTitle5 && (
                <div class='timeline right'>
                  <div class='card'>
                    <div class='card-body p-4'>
                      <h3
                        className='timeline-container-content-title'
                        dangerouslySetInnerHTML={{ __html: timelineTextTitle5 }}
                      />
                      <p
                        className='timeline-container-content-description'
                        dangerouslySetInnerHTML={{
                          __html: timelineTextDescription5,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {timelineTextTitle6 && (
                <div class='timeline left'>
                  <div class='card align-items-md-end'>
                    <div class='card-body p-4 align-items-md-end'>
                      <h3
                        className='timeline-container-content-title'
                        dangerouslySetInnerHTML={{ __html: timelineTextTitle6 }}
                      />
                      <p
                        className='timeline-container-content-description'
                        dangerouslySetInnerHTML={{
                          __html: timelineTextDescription6,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelineV1Component;
