import React from 'react';

const BorderCards = props => {
  const {
    borderCardsMainTitle,
    borderCardsTitle,
    borderCardsDescription,
    borderCardsSubitle,
    borderCardsText1,
    borderCardsText2,
    borderCardsText3,
    borderCardsText4,
    borderCardsText5,
    borderCardsText6,
    borderCardsText7,
    borderCardsText8,
    borderCardsText9,
    borderCardsText10,
    borderCardsText11,
    borderCardsText12,
    borderCardsText13,
    borderCardsText14,
    borderCardsText15,
    borderCardsText16,
    borderCardsText17,
    borderCardsText18,
    borderCardsText19,
    borderCardsText20,
    borderCardsText21,
    borderCardsText22,
    whiteBackground,
  } = props;

  return (
    <div
      className='section-container'
      style={{ background: whiteBackground && 'transparent' }}
    >
      <div className='section-container-main'>
        {borderCardsTitle && (
          <div className='section-container-main-wrapper smaller'>
            <div>
              <h3
                className='section-container-main-wrapper-big-title'
                dangerouslySetInnerHTML={{ __html: borderCardsMainTitle }}
              />
              <h3
                className='section-container-main-wrapper-title'
                style={{ color: whiteBackground && '#243062' }}
                dangerouslySetInnerHTML={{ __html: borderCardsTitle }}
              />
              <p
                className='section-container-main-wrapper-description'
                style={{ color: whiteBackground && '#000' }}
                dangerouslySetInnerHTML={{ __html: borderCardsDescription }}
              />
            </div>
            {borderCardsSubitle && (
              <p
                className='section-container-main-wrapper-description main-subtitle'
                dangerouslySetInnerHTML={{ __html: borderCardsSubitle }}
              />
            )}
          </div>
        )}

        <div className='section-container-content'>
          {borderCardsText1 && (
            <div className=' d-flex section-container-content-wrapper justify-content-between '>
              {borderCardsText1 && (
                <div className='section-container-main-wrapper border-test'>
                  <div className='d-flex'>
                    <div
                      className='section-container-content-icon'
                      style={{ backgroundColor: whiteBackground && '#243062' }}
                    >
                      1<div className='icon-shadow'></div>
                    </div>
                    <div className='section-container-content-body'>
                      <h3
                        className='section-container-main-wrapper-title'
                        style={{ color: whiteBackground && '#243062' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText1 }}
                      />
                      <p
                        className='section-container-main-wrapper-description'
                        style={{ color: whiteBackground && '#000' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText2 }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {borderCardsText3 && (
                <div className='section-container-main-wrapper border-top-mobile position-relative m-top-72'>
                  <div className='d-flex'>
                    <div
                      className='section-container-content-icon'
                      style={{ backgroundColor: whiteBackground && '#243062' }}
                    >
                      2<div className='icon-shadow'></div>
                    </div>
                    <div className='section-container-content-body'>
                      {' '}
                      <h3
                        className='section-container-main-wrapper-title'
                        style={{ color: whiteBackground && '#243062' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText3 }}
                      />
                      <p
                        className='section-container-main-wrapper-description'
                        style={{ color: whiteBackground && '#000' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText4 }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {borderCardsText5 && (
            <div className='d-flex  section-container-content-wrapper justify-content-between margin-top border-x position-relative'>
              {borderCardsText5 && (
                <div className='section-container-main-wrapper'>
                  <div className='d-flex'>
                    <div
                      className='section-container-content-icon'
                      style={{ backgroundColor: whiteBackground && '#243062' }}
                    >
                      3<div className='icon-shadow'></div>
                    </div>
                    <div className='section-container-content-body'>
                      {' '}
                      <h3
                        className='section-container-main-wrapper-title'
                        style={{ color: whiteBackground && '#243062' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText5 }}
                      />
                      <p
                        className='section-container-main-wrapper-description'
                        style={{ color: whiteBackground && '#000' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText6 }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {borderCardsText7 && (
                <div className='section-container-main-wrapper border-top-mobile position-relative m-top-72'>
                  <div className='d-flex'>
                    <div
                      className='section-container-content-icon'
                      style={{ backgroundColor: whiteBackground && '#243062' }}
                    >
                      4<div className='icon-shadow'></div>
                    </div>
                    <div className='section-container-content-body'>
                      <h3
                        className='section-container-main-wrapper-title'
                        style={{ color: whiteBackground && '#243062' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText7 }}
                      />
                      <p
                        className='section-container-main-wrapper-description'
                        style={{ color: whiteBackground && '#000' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText8 }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {borderCardsText9 && (
            <div className='d-flex section-container-content-wrapper  justify-content-between margin-top border-x position-relative'>
              {borderCardsText9 && (
                <div className='section-container-main-wrapper'>
                  <div className='d-flex'>
                    <div
                      className='section-container-content-icon'
                      style={{ backgroundColor: whiteBackground && '#243062' }}
                    >
                      5<div className='icon-shadow'></div>
                    </div>
                    <div className='section-container-content-body'>
                      {' '}
                      <h3
                        className='section-container-main-wrapper-title'
                        style={{ color: whiteBackground && '#243062' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText9 }}
                      />
                      <p
                        className='section-container-main-wrapper-description'
                        style={{ color: whiteBackground && '#000' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText10 }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {borderCardsText11 && (
                <div className='section-container-main-wrapper border-top-mobile position-relative m-top-72'>
                  <div className='d-flex'>
                    <div
                      className='section-container-content-icon'
                      style={{ backgroundColor: whiteBackground && '#243062' }}
                    >
                      6<div className='icon-shadow'></div>
                    </div>
                    <div className='section-container-content-body'>
                      {' '}
                      <h3
                        className='section-container-main-wrapper-title'
                        style={{ color: whiteBackground && '#243062' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText11 }}
                      />
                      <p
                        className='section-container-main-wrapper-description'
                        style={{ color: whiteBackground && '#000' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText12 }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {borderCardsText13 && (
            <div className='d-flex section-container-content-wrapper  justify-content-between margin-top border-x position-relative'>
              {borderCardsText13 && (
                <div className='section-container-main-wrapper'>
                  <div className='d-flex'>
                    <div
                      className='section-container-content-icon'
                      style={{ backgroundColor: whiteBackground && '#243062' }}
                    >
                      7<div className='icon-shadow'></div>
                    </div>
                    <div className='section-container-content-body'>
                      {' '}
                      <h3
                        className='section-container-main-wrapper-title'
                        style={{ color: whiteBackground && '#243062' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText13 }}
                      />
                      <p
                        className='section-container-main-wrapper-description'
                        style={{ color: whiteBackground && '#000' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText14 }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {borderCardsText15 && (
                <div className='section-container-main-wrapper border-top-mobile position-relative m-top-72'>
                  <div className='d-flex'>
                    <div
                      className='section-container-content-icon'
                      style={{ backgroundColor: whiteBackground && '#243062' }}
                    >
                      8<div className='icon-shadow'></div>
                    </div>
                    <div className='section-container-content-body'>
                      {' '}
                      <h3
                        className='section-container-main-wrapper-title'
                        style={{ color: whiteBackground && '#243062' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText15 }}
                      />
                      <p
                        className='section-container-main-wrapper-description'
                        style={{ color: whiteBackground && '#000' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText16 }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {borderCardsText17 && (
            <div className='d-flex section-container-content-wrapper  justify-content-between margin-top border-x position-relative'>
              {borderCardsText17 && (
                <div className='section-container-main-wrapper'>
                  <div className='d-flex'>
                    <div
                      className='section-container-content-icon'
                      style={{ backgroundColor: whiteBackground && '#243062' }}
                    >
                      9<div className='icon-shadow'></div>
                    </div>
                    <div className='section-container-content-body'>
                      {' '}
                      <h3
                        className='section-container-main-wrapper-title'
                        style={{ color: whiteBackground && '#243062' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText17 }}
                      />
                      <p
                        className='section-container-main-wrapper-description'
                        style={{ color: whiteBackground && '#000' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText18 }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {borderCardsText19 && (
                <div className='section-container-main-wrapper border-top-mobile position-relative m-top-72'>
                  <div className='d-flex'>
                    <div
                      className='section-container-content-icon'
                      style={{ backgroundColor: whiteBackground && '#243062' }}
                    >
                      10<div className='icon-shadow'></div>
                    </div>
                    <div className='section-container-content-body'>
                      {' '}
                      <h3
                        className='section-container-main-wrapper-title'
                        style={{ color: whiteBackground && '#243062' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText19 }}
                      />
                      <p
                        className='section-container-main-wrapper-description'
                        style={{ color: whiteBackground && '#000' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText20 }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {borderCardsText21 && (
            <div className='d-flex section-container-content-wrapper  justify-content-between margin-top border-x position-relative'>
              {borderCardsText21 && (
                <div className='section-container-main-wrapper'>
                  <div className='d-flex'>
                    <div
                      className='section-container-content-icon'
                      style={{ backgroundColor: whiteBackground && '#243062' }}
                    >
                      11<div className='icon-shadow'></div>
                    </div>
                    <div className='section-container-content-body'>
                      {' '}
                      <h3
                        className='section-container-main-wrapper-title'
                        style={{ color: whiteBackground && '#243062' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText21 }}
                      />
                      <p
                        className='section-container-main-wrapper-description'
                        style={{ color: whiteBackground && '#000' }}
                        dangerouslySetInnerHTML={{ __html: borderCardsText22 }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BorderCards;
